@import "../../styles/base.scss";

.container-fluid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .ramurl {
        font-size: small;
        color: $color-linkBlue;
    }

    .privacy-notice-div {
        display: flex;
        justify-content: flex-end;

        .privacy-notice-anchor {
            padding-right: 3px;

            a {
                color: $color-linkBlue;
            }
        }
    }
}
