@import '../../styles/base.scss';

.grid__container {
    margin: 10px 10px;
    border: 1px solid $color-lightGray;

    .ms-DetailsList {
        overflow-y: overlay;
        max-height: 280px;

        .ms-DetailsRow {
            color: $color-black;
        }

        .ms-DetailsList-headerWrapper {
            z-index: 1000;
            top: 0;
            position: sticky;

            .ms-DetailsHeader {
                padding-top: 0px;
            }

            .ms-DetailsHeader-cellSizer {
                cursor: ew-resize;

                &::after {
                    background: $color-white;
                }
            }

            div {
                background: $color-siteBlue;
                color: $color-white;
                padding: 0px;
                font-size: small;

                &:hover {
                    background: $color-siteBlue;
                    color: $color-white;
                    padding: 0px;
                    font-size: $font-size-small;
                }
            }
        }

        .ms-DetailsList-contentWrapper {
            .ms-DetailsRow-cell {
                border-right: solid $color-lightGray;
                border-width: 1px;

                &:last-child {
                    border-right: none;
                }
            }
        }
    }

    > .pagination {
        float: right;
        margin-top: 3px;
        margin-bottom: 50px;

        button {
            outline: none;
        }

        button.ms-Button {
            min-width: 32px;
            max-width: 32px;
            border-color: $color-lightGray;
            color: $color-lightBlack;

            .ms-Button-label {
                font-size: $font-size-small;
            }

            .ms-Button-icon {
                font-size: $font-size-small;
            }
        }

        button.ms-Button--primary {
            color: $color-white;
        }
    }
}

.Toaster {
    span.Toaster__manager-top-right {
        top: 60px !important;
    }

    .Toaster__alert {
        padding: 0.5rem 1.7rem 0.5rem 0.7rem;
        display: block;
        max-width: 250px;
        border-radius: 0;
        overflow: hidden;
        position: relative;
        box-shadow: rgba(52, 58, 64, 0.15) 0px 1px 10px 0px, rgba(52, 58, 64, 0.1) 0px 6px 12px 0px, rgba(52, 58, 64, 0.12) 0px 6px 15px -2px;
    }

    .Toaster__alert_close {
        padding: 2px 5px;
        outline: none;
        cursor: pointer;
        background-color: $color-transparent;
        position: absolute;
        top: 7px;
        right: 4px;
        border-radius: 0.4rem;
        border: 0;
        -webkit-appearance: none;
        font-size: $font-size-regular;
        font-weight: $font-weight-extraBold;
        line-height: 1;
        text-shadow: 0 1px 0 #fff;
        opacity: 0.5;

        &:focus {
            box-shadow: rgba(52, 58, 64, 0.15) 0px 0px 0px 3px;
        }
    }

    .Toaster__alert_text {
        box-sizing: border-box;
        font-family: $font-default;
        color: $color-lightBlack;
        -webkit-font-smoothing: antialiased;
        font-weight: $font-weight-normal;
        line-height: 1.5;
        font-size: $font-size-regular;
        margin: 0px;
    }

    .Toaster__message-wrapper {
        padding: 8px;
    }
}

.ms-Icon {
    color: $color-white;
}

.btn_disabled {
    cursor: not-allowed !important;
}