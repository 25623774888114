@import "../../../styles/base.scss";

.alert-dialog {
  .dialog-btn-primary,
  .dialog-btn-secondary {
    &.btn-danger {
      background-color: $color-errorLightRed;
      &:hover {
        background-color: $color-errorRed;
      }
      &.dialog-btn-secondary {
        color: $color-white;
      }
      &:focus,
      &:active,
      &:active:focus,
      &:not(:disabled):not(.disabled) {
        &:active:focus,
        &:active {
          background-color: $color-errorLightRed;
          outline: none;
          box-shadow: none;
        }
      }
    }
    &:focus,
    &:active,
    &:active:focus,
    &.active:focus {
      outline: none;
      box-shadow: none;
    }

    &.btn-success {
      background-color: $color-siteBlue;
      &:hover,
      &:active, 
      &:not(:disabled):not(.disabled):active{
        background-color: $color-btnHovering;
        &:focus{
          box-shadow: 0 0 0 0.1rem $color-shadowBlue;
        }
      }
      &.dialog-btn-secondary {
        color: $color-white;
      }
    }
  }

  .ms-Dialog-actions div {
    display: flex;
    justify-content: flex-end;
  }
}
