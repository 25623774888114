@import '../../styles/base.scss';

.debug__details__navigation {
    display: flex;
    align-items: center;
    flex-direction: column;

    > .debug__details__navigation__container {
        display: flex;
    }
}

.ms-Pivot {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
}

.ms-Fabric--isFocusVisible .ms-Pivot-link:focus {
    outline: $color-black solid 1.5px;
}

.ms-Pivot-link {
    width: fit-content;
    padding-left: 25px;
    margin: 4px;
    padding-right: 5px;
    min-height: 36px;
    line-height: 0px;
    background: $color-siteBlue;
    height: auto;
    position: relative;
    display: flex;
    align-items: center;
    color: $color-white;
    border: none;
    margin-right: 5px;
    font-weight: $font-weight-normal;
    font-size: $font-size-medium;

    &:focus {
        
        color: $color-white
    }

    &:hover {
        background: $color-siteBlue;

        &::before {
            left: auto;
            right: -18px;
        }
    }

    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        visibility: visible;
        width: 0;
        height: 0;
        border-left: 18px solid $color-white;
        border-top: 18px solid $color-transparent;
        border-bottom: 18px solid $color-transparent;
    }

    &:before {
        content: "";
        position: absolute;
        right: -18px;
        left: auto;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 18px solid $color-siteBlue;
        border-top: 18px solid $color-transparent;
        border-bottom: 18px solid $color-transparent;
        z-index: 1;
    }
}

.ms-Pivot-link.is-selected {
    background-color: $color-lightGreen;

    &:hover {
        background-color: $color-lightGreen;
    }

    &:before {
        border-left-color: $color-lightGreen;
    }
}

.ms-Pivot-link.bg-active {
    background-color: $color-siteBlue;

    &:before {
        border-left-color: $color-siteBlue;
    }
}

.ms-Pivot-link.is-disabled {
    background-color: $color-gray;

    &:before {
        border-left-color: $color-gray;
    }
}

.action__repair__container {
    display: flex;
    padding-bottom: 50px;
}

@media all and (max-width: 1070px) {
    .action__repair__container {
        flex-flow: column;
    }
}

.record__repair__container {
    padding-bottom: 50px;
}