@import "base.scss";

fieldset.section__header {
    border-top: 2px solid $color-siteBlue;
    margin: 10px;
}

fieldset.section__header > legend {
    padding-right: 10px;
    width: auto;
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
    font-family: sans-serif;
    font-feature-settings: normal;
    color: $color-siteBlue;
    margin-bottom: 0;
}

.primary__button {
    background: $color-siteBlue;
    padding: 2px 2px;
    display: flex;
    align-items: center;
    margin: 6px;
    border: none;

    &:focus {
        outline: 2px solid $color-btnHovering
    }

    &:hover {
        background: $color-btnHovering;
    }

    span {
        display: flex;
        height: 32px;
        min-width: 120px;
        padding-left: 10px;
        padding-right: 10px;
        align-items: center;
        justify-content: center;
        background: $color-siteBlue;
        color: $color-white;
        border: solid $color-white 1px;

        &:hover {
            background: $color-btnHovering;
        }
    }
}

.btn-loading {
    background: $color-siteBlue;

    &:hover {
        cursor: not-allowed;
        background: $color-siteBlue;
    }

    span {
        background: aliceblue !important;
        color: $color-siteBlue;
        border: solid $color-white 1px;

        .ms-Spinner {
            margin-right: 6px;
        }

        &:hover {
            background: aliceblue;
        }

        .loading-text {
            height: fit-content;
            width: fit-content;
            border: none;
        }

        .ms-Spinner-circle {
            border-color: $color-navigationBlue white white;
        }
    }
}

.btn_disabled {
    background: $color-gray;

    &:hover {
        cursor: not-allowed;
        background: $color-gray;
    }

    span {
        background: $color-gray;

        &:hover {
            background: $color-gray;
        }
    }
}

.form__fields__container {
    margin: 8px 10px 0px 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    flex: 1 0 200px;

    .form__field__label {
        min-width: 150px;
        width: auto;
        line-height: 30px;
        font-size: $font-size-regular;
        color: $color-lightBlack;
        font-weight: 600;
        white-space: nowrap;
    }

    .form__field__label.required::after {
        content: "*";
        color: $color-errorRed;
    }

    .form__field__datapairs {
        margin: 8px 10px;
        display: flex;
        width: 100%;
    }

    .form__field__input__container {
        display: flex;
        flex-wrap: wrap;
        margin: 0 30px;
        width: 100%;

        > div {
            width: 100%;
        }
    }

    .form__field__input__container.inline {
        margin-right: 0;
    }

    .form__field__datapairs__inline {
        display: flex;
        flex: 1 0 200px;
    }

    .form__field__input__container.error {
        .ms-Dropdown-title, .ms-TextField-fieldGroup, .ms-BasePicker-text{
            border-color:$color-errorRed;
        }
    }
}