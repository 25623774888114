@import "../../styles/base.scss";

nav {
    background-color: $color-siteBlue;
}

.navbar-nav {
    a {
        font-size: $font-size-medium;
        font-weight: $font-weight-bold;
        margin-left: 20px;
    }

    .nav-item {
        a.nav-link.nav-link.is-active {
            color: $color-white;
        }
    }
}

.navbar {
    .primary__button {
        span {
            width: 200px;
        }
    }
}

.navbar-nav.nav-right {
    line-height: 0.3em;

    .nav-item {
        a.nav-link {
            font-size: $font-size-regular;
            font-weight: $font-weight-bold;
            color: $color-white;
        }

        span.nav-link {
            font-size: 15px;
            font-weight: $font-weight-bold;
            color: $color-white;
        }

        border-right: 1.2px solid white;
    }
}

.ms-Fabric--isFocusVisible .root-40:focus::after {
    outline: $color-black auto 1.2px;
}

.ms-Fabric--isFocusVisible .root-48:focus::after {
    outline: $color-black auto 1.2px;
}

.navbar__action__button {
    background: transparent;
    border: none;
    outline: none;
    padding-left: 0px;
    color: $color-white;
    font-size: $font-size-regular;
    font-weight: $font-weight-bold;
    color: $color-white;

    &:hover,
    &:active,
    &:focus {
        background: transparent;
        border: none;
        outline: none;
        padding-left: 0px;
        color: $color-white;
        font-size: $font-size-regular;
        font-weight: $font-weight-bold;
        color: $color-white;
    }

    i {
        color: $color-white;
    }
}

@media (min-width: 300px) and (max-width: 1100px) {
    .ms-SearchBox {
        width: 100%;
    }

    .nav-item {
        border-right: 0px;
    }
}

@media all and (max-width: 970px) {
    .navbar-nav.nav-right .nav-item {
        span.nav-link {
            font-size: $font-size-verysmall;
        }

        a.nav-link {
            font-size: $font-size-small;
        }
    }

    .navbar-nav a {
        font-size: $font-size-small;
    }

    .navbar__action__button {
        font-size: $font-size-small;

        &:focus {
            font-size: $font-size-small;
        }
    }
}

@media all and (max-width: 570px) {
    .ms-SearchBox {
        width: 100px;
    }
}
