@import "../../../styles/base.scss";


.ms-MessageBar {
    padding: 10px;

    .ms-MessageBar-icon {
        display: flex;
        align-items: center;
        margin: 8px 8px 8px 12px;
        font-size: 24px;
    }

    .ms-MessageBar-text{
        align-items: center;
    }

    .messageView-title {
        font-size: $font-size-medium;
    }

    .messageView-subTitle{
        margin-top: 2px;
        font-size: 0.9em;
    }
}