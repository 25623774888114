@import '../../styles/base.scss';

.debug__flow__container {
    width: 50%;
}

.debug__flow__datacontainer {
    margin: 8px 10px 0px 10px;
    width: 100%;
    height: 100%;

    .debug__flow__lang__selection {
        margin: 10px 0;
    }

    .debug__flow__lang__selection .ms-ChoiceField {
        margin-right: 1.5rem;
        display: inline-block;
    }
}

.container__overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    height: 70vh;
    width: 85vw;
    overflow-y: hidden;
}

.ms-Stack {
    max-width: 100px;
    margin: 8px;
    padding: 8px;
    border: 2px solid $color-siteBlue;
    float: right;
    & > Label {
        font-size: 12px;
        color: $color-siteBlue;
    }
    & .icon-primary:hover {
        border: 0px;
        transition: transform .2s;
        transform: scale(1.25);
    }
    & > .icon-primary {
        border: 1px solid $color-siteBlue;
        border-radius: 0px;
        background-color: $color-white;
        & .ms-Icon {
            color: $color-siteBlue;
        }
    }
}