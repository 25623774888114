@import '../../styles/base.scss';

fieldset.dutinfo__header {
    border-top: 2px solid $color-siteBlue;
    margin: 10px;
    margin-right: 20px;

    > legend {
        padding-right: 10px;
        width: auto;
        font-size: $font-size-large;
        font-family: sans-serif;
        font-feature-settings: normal;
        font-variant: small-caps;
        color: $color-white;
    }
}

.dutinfo__datacontainer {
    margin: 8px 10px 0px 10px;
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 200px;
}

.dutinfo__datapairs {
    margin-bottom: 8px;
    min-width: 33.333333%;
    width: 300px;
    display: flex;

    > span {
        &::before {
            content: ' ';
        }
    }

    .dutinfo__datakey {
        font-weight: $font-weight-bold;
        margin-right: 8px;
        line-height: 22px;

        &::after {
            content: ":  ";
        }
    }

    span {
        margin-bottom: 0;
        font-size: $font-size-regular;
        color: $color-lightBlack;
        line-height: 22px;
        height: 24px;
    }
}

.dutinfo__datapairs__errordetail {
    margin-bottom: 8px;
    min-width: 66.666666%;
    width: 500px;
    display: flex;

    > span {
        &::before {
            content: ' ';
        }
    }

    .dutinfo__datakey {
        font-weight: $font-weight-bold;
        margin-right: 8px;
        line-height: 22px;

        &::after {
            content: ":  ";
        }
    }

    span {
        margin-bottom: 0;
        font-size: $font-size-regular;
        color: $color-lightBlack;
        line-height: 22px;
        height: 24px;
    }
}
