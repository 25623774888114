@import "../../styles/base.scss";

.repair-record-nav {
    background: $color-white;
}

.repair-record-button-container {
    display: flex;
    margin: 16px;
    justify-content: center;
}

.repair-record-nav-container {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    margin: 0 32px 8px 0px;
    border: 1px solid $color-siteBlue;
}

.callout {
    margin: 2px;
    padding: 8px 10px 0 10px;
    background: $color-lightGray;
}

.ms-FocusZone {
    flex-grow: 1;
}

.ms-Nav {
    margin-bottom: 16px;
    height: 100%;
}

.ms-Nav-link {
    &:focus {
        outline: none;
    }

    color: $color-black;
}

.repair-record-nav-pane {
    border-right: solid 1px $color-lightGray;
}

.repair-record-content {
    display: flex;
    padding: 8px 20px;
    justify-content: center;
}

.repair-record-form {
    display: flex;
    flex-grow: 5;
    padding: 12px 4px 20px 24px;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.repair-record-form__error {
    color: $color-errorRed;
    font-size: 12px;
    display: block;
}


.repair-details-container {
    border: 1px solid $color-siteBlue;
    height: fit-content;
    flex-basis: 420px;
    max-width: 500px;
}

@media all and (max-width: 970px) {
    .repair-record-content,
    .repair-record-nav-container {
        flex-flow: row wrap;
    }

    .repair-details-container {
        width: 280px;
    }
}

.ms-Dropdown {
    &:focus {
        outline: $color-fieldFocus auto 2px;
    }

    &:focus::after {
        outline: none;
        border: none;
    }
}

.ms-Dropdown-items {
    .ms-Button {
        outline: none;
    }
}


.ms-Dialog-action .ms-Button-icon {
    display: none;
}

.ms-Nav-groupContent {
    margin-bottom: 0px;
}

.repair-record-form .form__fields__container .form__fields__label {
    width: 24%;
}

.repair-record-form .form__fields__container .form__field__input__container {
    max-width: 280px;
}

.repair-record-form .form__fields__container .form__field__datapairs {
    justify-content: center;
}

.non-critical {
    margin: 0px;
    width: 100%;
}

.non-critical span {
    width: 100%;
    background: $color-linkBlue;
}

.repair-record-nav {
    border-bottom: solid 1px $color-lightGray;
}

.repair-record-nav-add-non-critical {
    border-bottom: none;
}

.repair-record-nav-add-non-critical .ms-Nav-navItems li:last-child div button {
    border-top: solid 2px $color-lightGray;
    border-bottom: solid 2px $color-lightGray;
}

.remove-component-details-container {
    width: 100%;
    display: flex;
    margin-bottom: 40px;
    margin-top: 16px;
    align-items: center;
    flex-direction: column;

    .ms-MessageBar {
        padding: 4px;

        .ms-MessageBar-content {
            justify-content: center;
            align-items: center;
        }
    }


    .btn-loading span .ms-Spinner {
        margin-right: 0px;
        margin-left: 16px;
    }


    .primary__button {

        &:focus {
            outline:2px solid #4c8ec7;
            }

        margin-top: 16px;

        span {
            width: 150px;
            background: $color-navigationBlue;
        }
    }

    .btn_disabled span {
        background: $color-gray;
    }
}
