@import '../../styles/base.scss';

.debug__action__container {
    width: 50%;
}


.debug__action__actionpairs {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;

    button {
        margin: 0 30px;
    }
}

@media all and (max-width: 1070px) {
    .form__field__datapairs {
        flex-flow: wrap;
    }

    .form__field__datapairs__inline {
        flex-flow: wrap;
    }

    .debug__action__actionpairs {
        flex-flow: wrap;
    }
}

.tag__picker {
    .ms-TagItem.is-selected {
        color: $color-lightBlack;
        background: $color-lightGray;

        > button {
            color: $color-lightBlack;

            &:focus, &:hover {
                background: $color-lightGray;
                outline: none;
            }
        }
    }
}

.tag__picker.disabled {
    .ms-TagItem > button {
        display: none;
    }
}
