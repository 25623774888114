@import '../../styles/base.scss';

.home-container {
    margin: 20px;
}

.home-screen {
    display: flex;
    width: 100%;
    height: 80vh;
    min-height: 300px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > h2 {
        color: $color-linkBlue;
    }
}

.ms-ProgressIndicator {
    width: 80vw;
}

.home-screen {
    .primary__button {
        span {
            width: 300px;
        }
    }
}
