@import '../../styles/base.scss';

.search-error-text{
    color:$color-errorRed;
    margin-top: 8px;
}

.search__container {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    .search__separator {
        background-color: #337ab7;
        color: $color-white;
        font-size: $font-size-large;
        padding-top: 3.5px;

        &::before {
            content: "|"
        }
    }

    .ms-SearchBox {
        height: 50px;
        max-width: 500px;
        background-color: $color-linkBlue;
        border-radius: 3px;
        border-radius: 3px 0 0 3px;
        border-right: none;

        .ms-Icon {
            color: $color-black;
        }
    }

    .ms-SearchBox.is-active {
        border-radius: 3px;
    }

    .ms-SearchBox-field {
        
        &::placeholder {
            font-weight: $font-weight-light;
            color: $color-white;
            font-size: $font-size-regular;
        }

        width: 70vh;
        font-weight: $font-weight-normal;
        color: $color-white;
        font-size: $font-size-regular;

        &:focus {
            &::placeholder {
                color: $color-lightGray;
            }
        }
    }

    .ms-Dropdown {
        display: flex;

        span[class^="ms-Dropdown-"] {
            height: 50px;
            display: inline-flex;
            align-items: center;
        }

        .ms-Dropdown-title {
            background-color: #337ab7;
            min-width: 170px;
            border-left: none;
            border-radius: 0 3px 3px 0;
            padding: 15px;
        }

        *[class^="ms-Dropdown-"],
        &:hover *[class^="ms-Dropdown-"],
        &:focus *[class^="ms-Dropdown-"] {
            color: $color-white;
        }
    }


    .ms-Dropdown-caretDownWrapper {
        padding-right: 10px;
    }

    .ms-SearchBox-icon, .ms-Dropdown-caretDown {
        font-size: $font-size-regular;
        font-weight: $font-weight-extraBold;
        color: $color-white;

        &:focus, &:hover {
            color: $color-white;
        }
    }
}
