@import 'styles/base.scss';
@import "@reach/skip-nav/styles.css";

/* .App {
  text-align: center;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.app-footer {
    background-color: $color-white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 42px;
    padding: 10px;
}

.app-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    height: 60px;
}

.app-body-container {
    margin-bottom: 42px;
    margin-top: 60px;
    position: absolute;
    width: 100%;
}