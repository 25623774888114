﻿//// font
$font-default: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

//// colors
$color-white: #FFFFFF;
$color-siteBlue: #3277a8;
$color-black: #000000;
$color-transparent: transparent;
$color-navigationBlue: #32557f;
$color-lightBlack: #323130;
$color-lightGray: #edebe9;
$color-gray: gray;
$color-green: green;
$color-lightGreen: #28a745;
$color-linkBlue: #337ab7;
$color-btnHovering: #4c8ec7;
$color-fieldFocus: #0078d4;
$color-shadowBlue: rgba(51,122,183,.5);
$color-errorRed: #dc3545;
$color-errorLightRed: rgb(220, 38, 44);
$color-mandatoryStar: red;
/// font size
$font-size-default:14px;
$font-size-large: 1.80em;
$font-size-medium: 1.30em;
$font-size-regular: 1em;
$font-size-small: 0.7em;
$font-size-verysmall: 0.5em;

/// font-weight
$font-weight-extraBold: 800;
$font-weight-bold: 600;
$font-weight-normal: 500;
$font-weight-light: 400;